<template>
  <div class="footer" :style="{ position: position ? 'inherit' : 'fixed' }">
    <!-- <img class="footer-logo" src="../assets/img/logo-pc-3.png" alt="" /> -->
    <p>
      <a
        class="beian"
        href="https://beian.miit.gov.cn/#/Integrated/index"
        target="_blank"
        rel="noopener noreferrer"
      >
        桂ICP备2022006874号-2
      </a>
      ©Copyright 2023
    </p>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    position: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {};
  },

  mounted() {},
  destroy() {},

  methods: {}
};
</script>

<style lang="scss" scoped>
.footer {
  // position: fixed;
  bottom: 0;
  left: 0;
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  font-size: 10px;
  background-color: #16161a;
  color: #b9b9b9;
  text-align: center;

  .footer-logo {
    margin-bottom: 10px;
    height: 35px;
    transition: all 0.2s;

    &:hover {
      cursor: pointer;
      transform: scale(1.05);
    }
  }

  .beian {
    &:hover {
      color: #ffffff;
    }
  }
}

@media (min-width: 768px) {
  .footer {
    position: inherit !important;
    padding-top: 10rem !important;
    padding-bottom: 8.125rem !important;
    font-size: 1.875rem !important;

    .footer-logo {
      margin-bottom: 3.8rem;
      height: 7.75rem;
    }
  }
}
</style>
