<template>
  <div class="play-page">
    <HeaderMb ref="Temp" :path="false"></HeaderMb>
    <Header ref="Temp"></Header>

    <el-main class="content page-main">
      <div class="video-title">{{ videoName }}</div>
      <!-- mb -->
      <div
        class="video-box header-xs-box hidden-sm-and-up"
        :style="{
          width: bannerStyleMb.w + 'px',
          height: bannerStyleMb.h + 'px'
        }"
      >
        <SeriesPreview
          v-if="bannerStyle.h"
          :videoUrl="videoUrl"
          :seriesImage="videoCover"
          :width="bannerStyleMb.w"
          :height="bannerStyleMb.h"
          :autoplay="true"
          :controls="true"
          :loop="false"
          :muted="false"
          preload="auto"
          :showPlay="false"
          :playWidth="96"
          zoom="cotain"
        ></SeriesPreview>
      </div>
      <!-- pc -->
      <div
        class="video-box hidden-xs-only"
        :style="{
          width: bannerStyle.w + 'px',
          height: bannerStyle.h + 'px'
        }"
      >
        <SeriesPreview
          v-if="bannerStyle.h"
          :videoUrl="videoUrl"
          :seriesImage="videoCover"
          :width="bannerStyleMb.w"
          :height="bannerStyleMb.h"
          :autoplay="true"
          :controls="true"
          :loop="false"
          :muted="false"
          preload="auto"
          :showPlay="false"
          :playWidth="96"
          zoom="cotain"
        ></SeriesPreview>
      </div>

      <div class="video-number">
        <p class="all-nm  header-xs-box hidden-sm-and-up">
          共{{ totalSeries || "-" }}集
        </p>
        <div class="describe-mb header-xs-box hidden-sm-and-up">
          {{ videoDesc }}
        </div>
        <div class="video-number-header hidden-xs-only">
          <p class="return" @click="back()">
            返回<i class="icon icon-a-gengduo1"></i>
          </p>
          <div class="video-name">
            <p class="name">{{ videoName }}</p>
            <p class="number">已完结，共{{ totalSeries || "-" }}集</p>
            <p class="describe">
              {{ videoDesc }}
            </p>
          </div>
        </div>
        <div class="list" v-if="videoList">
          <div v-for="(item, i) in videoList" :key="item.seriesIndex">
            <div
              @click="onSelected(item, i)"
              :class="[
                'number-item',
                i === activeIndex ? 'bj-active' : '',
                item.videoUrl ? 'mf' : ''
              ]"
            >
              {{ i + 1 }}
              <img
                v-if="!item.videoUrl"
                class="lock-img"
                src="../assets/img/lock.png"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="list"></div>
    </el-main>
    <Footer :position="true"></Footer>
  </div>
</template>
<script>
import Footer from "../../src/components/Footer";
import Header from "../../src/components/Header";
import HeaderMb from "../../src/components/HeaderMb";
export default {
  components: {
    Footer,
    Header,
    HeaderMb
  },
  data() {
    return {
      bannerStyle: {
        w: 100,
        h: 195
      },
      bannerStyleMb: {
        w: 100,
        h: 195
      },
      picWordList: [],
      page: 1,
      pageSize: 10,
      totalPage: 1,
      videoCover: "",
      videoUrl: "",
      activeIndex: 0,
      payNumber: 12,
      windowHeight: 0,
      videoList: [],
      videoDesc: "",
      totalSeries: "",
      videoName: "",
      seriesImage: ""
    };
  },
  destroy() {},
  mounted() {
    let bannerWidth = document.documentElement.clientWidth - 28;
    let windowHeight = document.body.clientHeight;
    let tempH = this.$refs.Temp.$el.offsetHeight || 0;
    this.bannerStyleMb = {
      w: bannerWidth,
      h: bannerWidth * 1.77
    };
    this.bannerStyle = {
      w: (windowHeight - tempH) * 0.56 - 28,
      h: windowHeight - tempH
    };

    window.onresize = () => {
      return (() => {
        let bannerWidth = document.documentElement.clientWidth - 28;
        let windowH = document.body.clientHeight;
        let tempHeight = this.$refs.Temp.$el.offsetHeight || 0;
        this.bannerStyleMb = {
          w: bannerWidth,
          h: bannerWidth * 1.77
        };
        this.bannerStyle = {
          w: (windowHeight - tempHeight) * 0.56,
          h: windowHeight - tempHeight
        };
      })();
    };
  },
  created() {
    let query = this.$route.query;
    if (query?.videoId) {
      this.getSeriesList(query?.videoId || "");
    }
  },
  methods: {
    back() {
      this.$router.back();
    },
    getSeriesList(videoId) {
      this.$api.getVideoSeries(videoId).then(res => {
        if (res?.code === 200) {
          let temp = res?.data;
          if (temp) {
            this.totalSeries = temp[0]?.totalSeries;
            this.videoCover = temp[0]?.videoImage;
            this.videoName = temp[0]?.videoName;
            this.videoUrl = temp[0]?.videoUrl;
            this.videoDesc = temp[0]?.videoDesc;
            this.videoList = temp;
          }
        }
      });
    },
    onSelected(item, i) {
      if (!item.pay && item.fee > 0) {
        this.$message({
          message: "敬请期待！",
          type: "warning"
        });
        return;
      }
      this.activeIndex = i;
      this.videoUrl = this.videoList[i].videoUrl;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/play.scss";
@import "../assets/styles/mediaPlay.scss";
</style>
