<template>
  <!-- PC端头部 -->
  <el-header ref="Temp" class="page-header hidden-xs-only">
    <div class="logo-box">
      <router-link key="collapse" class="sidebar-logo-link min" to="/">
        <!-- <img src="../assets/img/logo-pc.png" /> -->
      </router-link>
    </div>

    <div class="entry">
      <div class="cooperate cooperate-1">
        <a href="https://mag.qiezidj.com/copyright" target="_blank">
          <i class="icon icon-banquanshang-01"></i>
          <span>版权商</span>
        </a>
      </div>
      <div class="cooperate cooperate-2">
        <a href="https://mag.qiezidj.com/company" target="_blank">
          <i class="icon icon-fenxiaoshang"></i>
          <span>分销商</span>
        </a>
      </div>
    </div>
  </el-header>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {},
  methods: {
    onLogo() {
      this.$router.push({
        path: "/"
      });
    }
  }
};
</script>

<style lang="scss" scoped>
// PC端头部
.page-header {
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 8.125rem !important;
  background-color: #16161a;
  .logo-box {
    img {
      height: 53px;
    }
  }
  .active {
    position: relative;
    &::after {
      position: absolute;
      top: 4.8125rem;
      left: 50%;
      transform: translateX(-50%);
      content: "";
      display: block;
      width: 30px;
      height: 6px;
      border-radius: 3px;
    }
  }
  .header-right {
    display: flex;
    justify-content: space-between;
    height: 100%;
    .menu {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 100%;
      li {
        margin-right: 3.25rem;
        display: flex;
        align-items: center;
        height: 100%;
        text-align: center;
        font-size: 1rem;
        cursor: pointer;
        color: #727475;
        list-style: none;
        transition: all 0.2s;
        &:hover {
          color: #ffffff;
        }
        &:last-child {
          margin-right: 0;
        }
      }
      .active {
        color: #ffffff;
      }
    }
  }
}
// 合作入口
.entry {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  .cooperate {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
    i {
      margin-bottom: -6px;
      font-size: 26px;
    }
    span {
      display: block;
      width: 80px;
      height: 30px;
      line-height: 30px;
      font-size: 16px;
      background: linear-gradient(90deg, #122a82 0%, #fe2c4c 100%);
      border-radius: 8px;
      transform: scale(0.6);
      font-weight: bold;
    }
  }
  .cooperate-1 {
    right: 70px;
  }
  .cooperate-2 {
    right: 0;
  }
}

@media (min-width: 768px) {
  .page-header {
    padding-right: calc(8% + 60px);
    padding-left: 160px;
    .entry {
      .cooperate {
        cursor: pointer;
        i {
          margin-bottom: 0;
          font-size: 36px;
        }
        span {
          transform: scale(1);
        }
      }
      .cooperate-1 {
        right: 100px;
      }
      .cooperate-2 {
        right: 0;
      }
    }
  }
}
</style>
