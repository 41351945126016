<template>
  <div ref="Temp" class="header-xs-box hidden-sm-and-up">
    <div class="return" @click="onRoute(true)">
      <i class="icon icon-arrow-right1-copy"></i>
      返回
    </div>
    <img @click="onHome(true)" class="logo-img" src="../assets/img/logo.png" />
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    path: {
      type: Boolean,
      required: true,
      default: null
    }
  },
  mounted() {},

  methods: {
    onHome() {
      this.$router.push({
        path: "/home"
      });
    },
    onRoute(type, i) {
      if (this.path) {
        this.$router.push({
          path: "/home"
        });
      } else {
        this.$router.back();
      }
      // this.$router.push({
      //   path: "/play",
      //   query: {
      //     id: i
      //   }
      // });
    }
  }
};
</script>

<style lang="scss" scoped>
.header-xs-box {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 68px;
  background-color: #16161a;
  .logo-img {
    margin: 20px 14px 0 0;
    height: 32px;
  }
  .return {
    margin-left: 16px;
    display: flex;
    align-items: center;
    font-size: 14px;
    i {
      margin-right: 10px;
      color: #fe2c4c;
    }
  }
}
</style>
