var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"play-page"},[_c('HeaderMb',{ref:"Temp",attrs:{"path":false}}),_c('Header',{ref:"Temp"}),_c('el-main',{staticClass:"content page-main"},[_c('div',{staticClass:"video-title"},[_vm._v(_vm._s(_vm.videoName))]),_c('div',{staticClass:"video-box header-xs-box hidden-sm-and-up",style:({
        width: _vm.bannerStyleMb.w + 'px',
        height: _vm.bannerStyleMb.h + 'px'
      })},[(_vm.bannerStyle.h)?_c('SeriesPreview',{attrs:{"videoUrl":_vm.videoUrl,"seriesImage":_vm.videoCover,"width":_vm.bannerStyleMb.w,"height":_vm.bannerStyleMb.h,"autoplay":true,"controls":true,"loop":false,"muted":false,"preload":"auto","showPlay":false,"playWidth":96,"zoom":"cotain"}}):_vm._e()],1),_c('div',{staticClass:"video-box hidden-xs-only",style:({
        width: _vm.bannerStyle.w + 'px',
        height: _vm.bannerStyle.h + 'px'
      })},[(_vm.bannerStyle.h)?_c('SeriesPreview',{attrs:{"videoUrl":_vm.videoUrl,"seriesImage":_vm.videoCover,"width":_vm.bannerStyleMb.w,"height":_vm.bannerStyleMb.h,"autoplay":true,"controls":true,"loop":false,"muted":false,"preload":"auto","showPlay":false,"playWidth":96,"zoom":"cotain"}}):_vm._e()],1),_c('div',{staticClass:"video-number"},[_c('p',{staticClass:"all-nm header-xs-box hidden-sm-and-up"},[_vm._v(" 共"+_vm._s(_vm.totalSeries || "-")+"集 ")]),_c('div',{staticClass:"describe-mb header-xs-box hidden-sm-and-up"},[_vm._v(" "+_vm._s(_vm.videoDesc)+" ")]),_c('div',{staticClass:"video-number-header hidden-xs-only"},[_c('p',{staticClass:"return",on:{"click":function($event){return _vm.back()}}},[_vm._v(" 返回"),_c('i',{staticClass:"icon icon-a-gengduo1"})]),_c('div',{staticClass:"video-name"},[_c('p',{staticClass:"name"},[_vm._v(_vm._s(_vm.videoName))]),_c('p',{staticClass:"number"},[_vm._v("已完结，共"+_vm._s(_vm.totalSeries || "-")+"集")]),_c('p',{staticClass:"describe"},[_vm._v(" "+_vm._s(_vm.videoDesc)+" ")])])]),(_vm.videoList)?_c('div',{staticClass:"list"},_vm._l((_vm.videoList),function(item,i){return _c('div',{key:item.seriesIndex},[_c('div',{class:[
              'number-item',
              i === _vm.activeIndex ? 'bj-active' : '',
              item.videoUrl ? 'mf' : ''
            ],on:{"click":function($event){return _vm.onSelected(item, i)}}},[_vm._v(" "+_vm._s(i + 1)+" "),(!item.videoUrl)?_c('img',{staticClass:"lock-img",attrs:{"src":require("../assets/img/lock.png")}}):_vm._e()])])}),0):_vm._e()]),_c('div',{staticClass:"list"})]),_c('Footer',{attrs:{"position":true}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }